<!--
Compenent that displays a banner with the emulators info.
useEmulators from @/services/firebase.js
useFunctionsEmulators from @/services/functions.js
-->
 
<template>
<b-alert variant="warning" :show="firestoreEmulator || functionsEmulator">
  Emulators are currently in use: Firestore: {{ firestoreEmulator }} - Functions: {{ functionsEmulator }}
</b-alert>
</template>

<script>
import { useEmulators } from '@/services/firebase'
import { useFunctionsEmulators } from '@/services/functions'
import { getLog } from '@/services/log'
const log = getLog('Emulators')

export default {
  computed: {
    firestoreEmulator() {
      return useEmulators
    },
    functionsEmulator() {
      return useFunctionsEmulators
    }
  },
  created() {
    log.log('Firestore emulator:', useEmulators)
    log.log('Functions emulator:', useFunctionsEmulators)
  }
}
</script>


